import axios from "@/utils/axios";

export const getMyBuyCertList = () => axios.get(`/api/course/getMyBuyCertList`);
export const selectPcCourseDetailByCurrId = (data: { currId: string }) =>
  axios.get(`/api/course/certificate/selectPcCourseDetailByCurrId`, {
    params: data,
  });
export const getPlayInfo = (data: any) =>
  axios.get(`/api/course/vod/getPlayInfo`, { params: data });
export const addCollectCourse = (data: any) =>
  axios.post(`/api/course/collect/addCollectCourse`, data);
export const deleteCollectCourse = (data: any) =>
  axios.post(`api/course/collect/deleteCollectCourse`, data);
export const certificateDetail = (data: { id: string }) =>
  axios.get(`/api/course/certificate/detail/${data.id}`);
export const selectListByCertId = (data: { certId: string }) =>
  axios.get("/api/course/certificate/selectListByCertId", { params: data });
export const addCourseViewingRecords = (data: any) =>
  axios.post(`/api/course/addCourseViewingRecords`, data);
export const selectSubjectListByWareId = (data: any) =>
  axios.get(`/api/exam/subject/selectSubjectListByWareId`, { params: data });
export const insertClassroomPracticeAnswer = (data: any) =>
  axios.post(`/api/exam/subject/insertClassroomPracticeAnswer`, data);
export const insertTanPracticeAnswer = (data: any) =>
  axios.post(`/api/exam/subject/insertTanPracticeAnswer`, data);
